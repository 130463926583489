if (pageDetails.fileName === 'contact.html' || pageDetails.fileName === 'contact') {
    window.addEventListener('load', () => {
        const fields = Array.from(document.getElementsByClassName('inputData'));
        
        const emailOptionLinks = Array.from(document.querySelector('.option:last-of-type').children);
        emailOptionLinks.forEach((link) => {
            link.addEventListener('click', () => {
                window.scroll({
                    top: fields[0].getBoundingClientRect().top + window.pageYOffset - 90,
                    behavior: 'smooth'
                });

                fields[0].focus();
            });
        });
        
        // formData is any of the fields below when they are undefined
        const formData = {
            name: undefined,
            email: undefined,
            phone: undefined,
            message: undefined
        };

        // in the fields Array a function is called once for each element in the array
        fields.forEach((field) => {
            let placeholder = field.value;
            let valid;

            field.addEventListener('focusout', () => {
                // Check for validity when the user loses focus (exits) from
                // the input field, toggle field class and button disabled
                // attribute when appropriate
                valid = field.validity.valid;
                // if the field is in focus it will be looking for a field value
                // or else (if it isn't in focus) the field will show as undefined
                if (valid && field.value !== '') {
                    field.classList.add('filled');
                    formData[field.name] = field.value;
                } else {
                    field.classList.remove('filled');
                    field.value = placeholder;
                    formData[field.name] = undefined;
                }
                // if every value in the form is equal to or greater than the value parameters
                // set to define an entry the submit button won't be disabled. Otherwise it will be.
                if (Object.values(formData).every(value => value !== undefined)) {
                    submitButton.disabled = false;
                } else {
                    submitButton.disabled = true;
                }
            });

            field.addEventListener('input', () => {
                // Check for validity while the user is typing
                // toggle button disabled attribute when appropriate
                valid = field.validity.valid;
                if (valid && field.value !== '') {
                    formData[field.name] = field.value;
                } else {
                    formData[field.name] = undefined;
                }

                if (Object.values(formData).every(value => value !== undefined)) {
                    submitButton.disabled = false;
                    submitButton.style.color = 'black';
                } else {
                    submitButton.disabled = true;
                }
            });

            field.addEventListener('focusin', () => {
                // If user inputted text has not been added to the field
                // then remove the placeholder text.
                if (!field.classList.contains('filled')) {
                    field.value = '';
                }
            });

        });

        const emailPrompt = document.getElementById('email-prompt');
        const submitButton = document.getElementById('submit-button');
        submitButton.addEventListener('click', (e) => {
            e.preventDefault();
            // Add recaptcha token to the form data
            grecaptcha.ready(function() {
                grecaptcha.execute('6Leoo8AZAAAAADVzzz5-hsY-dqKiY_fpAOKoahjB', {action: 'submit'}).then(function(token) {
                    formData['token'] = token;
                    let userData = JSON.stringify(formData);
                    console.log(userData);
                    // setup http request and response handler
                    let xhr = new XMLHttpRequest();
                    xhr.onreadystatechange = function() {
                        if (this.readyState == 4 && this.status == 200) {
                            // Replace the paragraph element above the form with the server's response.
                            emailPrompt.innerHTML = this.responseText;

                            if ((window.pageYOffset + 80) > (emailPrompt.getBoundingClientRect().top + window.pageXOffset)) {
                                window.scroll({
                                    top: emailPrompt.getBoundingClientRect().top + window.pageYOffset - 80,
                                    behavior: 'smooth'
                                });
                            }
                        }
                    };
                    // send request
                    xhr.open("POST", "submission-script.php", true);
                    xhr.setRequestHeader("Content-Type", "application/json");
                    xhr.send(userData);
                });
            });
            // disable submit button
            submitButton.disabled = true;
        });
    });
}

