window.addEventListener('load', () => {
    const topOverlay = document.getElementById('top-overlay');
    const overlayContainer = document.getElementById('overlay-container');

    const logoWrapper = document.getElementById('logo-wrapper');
    const logo = document.getElementById('logo');

    logo.addEventListener('click', () => {
        window.location.href = '/';
    });

    const navMenu = document.getElementById('nav-menu');
    const menuIcon = document.getElementById('menu-icon');

    const siteLinksContainer = document.getElementById('site-links');
    const siteLinks = Array.from(siteLinksContainer.getElementsByTagName('a'));
    const bigMenubtn = document.querySelector('.big-menu-btn');
    const getOffer = document.getElementById('offer');

    let scrollPoint = 100;
    let overlayToggled = window.pageYOffset >= scrollPoint;

    const logoBP1 = 300; // for width
    const iPhone5 = 320; // for width
    const iPhone678Plus = 414; // for width
    const srfcDuo = 540; // for width
    const iPad = 768; // for width
    const overiPad = 769; // for width
    const iPadPro = 1024; // for width
    const smallMonitor = 1200; // for width

    const landscapeBP = 480; // for height, pairs with logoBP2

    function toggleBar() {
        //anime({
        //    targets: topOverlay,
        //    height: overlayToggled
        //        ? 70 +'px'
        //        : (window.innerWidth > logoBP2)
        //            ? 175 +'px'
        //            : (175 / 2) +'px',
        //    duration: 600,
        //    easing: 'easeOutQuad'
        //});

        if (overlayToggled || window.innerWidth <= logoBP1) {
            // If the overlay is toggled (at any viewport width),
            // set the top bar height to 70px
            
            anime({
                targets: topOverlay,
                duration: 550,
                height: '70px',
                easing: 'easeOutQuad'
            });

        } else {
            if (window.innerWidth > overiPad && window.innerHeight > landscapeBP) {
                // If the viewport is wide enough for the bigger "large form"
                // logo, set the top bar height to 175px (default)
                
                anime({
                    targets: topOverlay,
                    duration: 200,
                    height: '175px',
                    easing: 'easeOutQuad'
                });

            } else {
                // else set the height to half that height
                // (logo will also become smaller)
                
                anime({
                    targets: topOverlay,
                    duration: 200,
                    height: '87.5px',
                    easing: 'easeOutQuad'
                });
            }
        }
    }

    const logoImage = logo.firstElementChild;
    function toggleLogo() {
        if (overlayToggled || window.innerWidth <= logoBP1) {
            // If the overlay is toggled OR the screen width is <= 20px wide,
            // set the logo to its smallest form (without the background) and
            // make it appear on the left of the screen.
            logoImage.setAttribute('src', logoImage.getAttribute('data-small'));
            logo.classList.replace('large', 'small');

            anime({
                targets: logoWrapper,
                duration: 600,
                easing: 'easeOutQuad'
            });

            anime({
                targets: logo,
                transitionDelay: 10,
                duration: 600,
                marginLeft: '',
                marginTop: '',
                width: '',
                height: '',
                padding: '0px',
                easing: 'easeOutQuad'
            });

        } else {
            // If the overlay is untoggled (scroll position is at the very top)
            // Reset the logo to its default styles based on viewport width

            if (window.innerWidth > iPad && window.innerHeight > landscapeBP) {
                logoImage.setAttribute('src', logoImage.getAttribute('data-large'));
                logo.classList.replace('small', 'large');
                logo.style.transform = 'scale(.56)';
                

                anime({
                    targets: logoWrapper,
                    duration: 600,
                    easing: 'easeOutQuad'
                });
    
                anime({
                    targets: logo,
                    transitionDelay: 10,
                    duration: 600,
                    scale: 1,
                    marginLeft: '',
                    marginTop: '',
                    width: '',
                    height: '',
                    padding: '',
                    easing: 'linear'
                });

            } else {
                logo.style.width = '70px';
                logo.style.height = '70px';
                logo.style.padding = '0px';
            }
        }
    }

    const iconBP = 570;

    function toggleMenu() {
        if (overlayToggled || window.innerWidth <= logoBP1) {
            // If the overlay is toggled OR the screen width is <= 20px wide,
            
            anime({
                targets: bigMenubtn,
                scale: .5,
                duration: 600,
                easing: 'easeOutQuad'
            });

            anime({
                targets: getOffer,
                scale: 1.3,
                duration: 400,
                easing: 'easeOutQuad'
            });

            if (window.innerWidth <= iPad) {
                //Adjust size of the Big Menu Button (bigMenubtn) on iPad screen
                anime({
                    targets: bigMenubtn,
                    scale: .75,
                    duration: 600,
                    easing: 'easeOutQuad'
                });
            }

            if (window.innerWidth <= iconBP) {
                // Adjust the positioning of the siteLinksContainer if the
                // viewport width is <= the menu icon breakpoint.
                siteLinksContainer.style.top = 70 + 'px';
            }

        } else {
            // If the overlay is untoggled (scroll position is at the very top)
            // Reset the menu styles to their default values
            
            anime({
                targets: bigMenubtn,
                scale: 1,
                duration: 600,
                easing: 'easeOutQuad'
            });
            anime({
                targets: getOffer,
                scale: 1,
                duration: 400,
                easing: 'easeOutQuad'
            });

            if (window.innerWidth <= iconBP) {
                // Adjust the positioning of the siteLinksContainer if the
                // viewport width is <= the menu icon breakpoint.
                siteLinksContainer.style.top = '';
            }
        }
    }

    function toggleOverlay() {
        toggleBar();
        toggleLogo();
        toggleMenu();
    }

    // Apply transformations if the page has been reloaded
    if (overlayToggled) {
        toggleOverlay();
    }

    // -------------------------------------------- Menu Icon Button --------------------------------------------
    let menuOpen = false;
    menuIcon.addEventListener('click', () => {
        menuIcon.classList.toggle('open');

        // Get the total scroll height of the site links
        let siteLinksHeight = siteLinks.reduce((accum, current) => {
            return accum + 50 + current.scrollHeight;
        }, 0);

        if (!menuOpen) {
            // If tapped/clicked set the menu to opened and reveal the site links
            siteLinksContainer.style.height = siteLinksHeight + 'px';
            menuOpen = true;
        } else {
            // hide the site links if already opened
            siteLinksContainer.style.height = 0 + 'px';
            menuOpen = false;
        }

    });

    // -------------------------------------------- Other Event Handlers --------------------------------------------
    window.addEventListener('scroll', () => {
        // Toggle the overlay when scrolling past the scroll point
        if (!overlayToggled && window.pageYOffset >= scrollPoint) {
            overlayToggled = true;
            toggleOverlay();
        } else if (overlayToggled && window.pageYOffset < scrollPoint) {
            overlayToggled = false;
            toggleOverlay();
        }
    });

    // toggle the overlay (applies the correct transformation) if the page is resized
    window.addEventListener('resize', toggleOverlay);
});